import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import bsi_report from '../img/report-1.png';

const reports = [
  {
    title: 'temtum Web Application Test Report',
    file: 'CSIRUKPRJ-366-RPT-01-Dragon-InfoSec-Temtum-Wallet-Web-Application-Test-Report-v1.0.pdf'
  },
  {
    title: 'temtum iOS Application Security Assessment',
    file: 'CSIRUKPRJ-372-RPT-01-Dragon-Infosec-Ltd-Temtum-iOS-Application-Security-Assessment-v1.0.pdf'
  },
  {
    title: 'temtum Performance Test Report',
    file: 'CSIRUKPRJ-594-Performance-Test-Temtum-Crypto-Currency-Network-v1.0.pdf'
  },
  {
    title: 'temtum Security Assessment Report',
    file: 'IA14113-RPT-01-Dragon-InfoSec-Blockchain-Security-Assessment-Report-v1.0.pdf'
  },
  {
    title: 'temtum Web Application Re-Test Report',
    file: 'IA14347-RPT-01-Dragon-InfoSec-Dragon-Wallet-Web-Application-Test-Report-v1.0.pdf'
  },
  {
    title: 'temtum Web Wallet Re-Test Report',
    file: 'PRJ336-RPT-01-Dragon-InfoSec-Web-User-Interface-(Retest)-Web-Application-Test-Report-v1.0.pdf'
  }
];

const Reports = () => (
  <Layout>
    <div className="skipScroll">
      <Helmet
        title={'temtum Security Testing | BSI Crest Accredited Blockchain Pen Testing '}
        meta={[
          {
            name: 'description',
            content: 'The temtum Network has been independently tested by BSI, a Crest accredited agency, to verfify claims on speed, scalability and security.'
          }
        ]}
      />
      <div className="hd-space"/>
      <div className="page">
        <section className="bg-blue-3 text-white skipScroll bg-reports h-100X">
          <div className="darkblue video-overlay"></div>
          <div className="blueblue video-overlay"/>
          <div className="container">
            <div className="row">
              <div className="col-md-6 mx-auto text-center">
                <h1 className="display-2 font-weight-normal pb-3">BSI Test Reports</h1>
                <p className="display-4X pb-5">
                  Temporal Blockchain and the temtum Wallet Application, independently tested and verified by BSI Cybersecurity & Information Resliance.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {
                reports.map( (item) => (
                  <div key={item.title.toLowerCase().replace(/\s/g, '-')} className="col-lg-4 mx-auto">
                    <div className="boxeddark">
                      <img src={bsi_report} width="200px" alt=""/>
                      <h1 className="display-4 mb-4">
                        {item.title}
                      </h1>
                      <p></p>
                      <a href={require(`../reports/${item.file}`)} className="btn btn-outline-secondary btn-block">
                        Download Report
                      </a>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
        <section className="bg-white text-dark skipScroll ">
          <div className="container my-5X">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="">
                  <div className="">
                    <div className="boxedblueXX">
                      <h6 className="display-upper-1 headline red text-grey pb-3 mb-4">
                        Tests carried out by
                      </h6>
                      <h1 className="display-3 mb-4">
                        British Standards Institution (BSI)
                      </h1>
                      <p>BSI Group, also known as the British Standards Institution (BSI), is the national standards body of the United Kingdom. BSI produces technical standards on a wide range of products and services, and also supplies certification and standards-related services to businesses.
                      </p>
                      <a href="https://www.bsigroup.com/en-GB/" target="_blank" rel="noopener noreferrer" className="btn btn-outline-secondary scroll">
                        Visit www.bsigroup.com
                        <i className="fas fa-external-link-alt ml-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 mx-auto">
                <img src={require('../img/partners/partner-bsi.png')} width="" alt=""/>
              </div>
            </div>
          </div>
        </section>
        }
      </div>
    </div>
  </Layout>
);
export default Reports;
